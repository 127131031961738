import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'

import '../styles/blog.sass'

export default function Template({ data, pageContext }) {
  const { markdownRemark: post } = data
  const { prev, next } = pageContext

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <Banner class_suffix="deep" />
      <div className="blog-post-container">
        <div className="blog-post">
          <div className="blog-post-header">
            <h1 className="title">{post.frontmatter.title}</h1>
            <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
          </div>
          <hr className="blog-header-separator" />
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <hr />
          <div className="blog-post-footer">
            {prev && (
              <Link to={prev.frontmatter.path} rel="prev" className="prev-link truncate-left">
                {prev.frontmatter.title}
              </Link>
            )}
            {next && (
              <Link to={next.frontmatter.path} rel="next" className="next-link truncate-right">
                {next.frontmatter.title}
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}


export const postQuery = graphql`
  query EventPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: "de")
        path
        title
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
